'use strict'

/**
 * 
 * Webview || iframe communication
 * 
 */
class iJsBridge {
    constructor(options) {
        this.alert = options.alert || window.alert;
    }

    /**
     * 
     * @param {
     * method:alipay.auth
     * postData: object or string
     * callback:function xx() or array
     * } options 
     * call({method:"xxx.yy",postData:{a:1,b:2},callback:function object)
     * call({method:"xxx.yy")
     * call({method:"xxx.yy",postData:)
     * call({method:"xxx.yy",callback)
     * mode = 1,2,3,4
     */
    call(options) {
        try {
            if (!options.method)
                throw "method not allow empty";
            const args = options.method.split('.');
            const clsName = args[0];
            const methodName = args[1];
            let postData = options.postData || {};
            const fnName = options.callback.name.replace("bound ", "")
            window[fnName] = options.callback;

            if (typeof (postData) == "object") {
                postData = JSON.stringify(postData);
            }
            let returnValue = undefined;
            if (window.webkit) {
                window.webkit.messageHandlers[clsName].postMessage({
                    method: methodName,
                    body: postData,
                    callback: fnName
                });
            } else {
                returnValue = window[clsName][methodName]([postData, fnName])
            }
            return returnValue;
        } catch (error) {
            console.log("javascript bridge call options:", options);
            console.log("javascript bridge call exception:", error);
            this.alert(`bridge exception:${error.message},please check:${JSON.stringify(options)}`);
            return undefined;
        }
    }

}

export default iJsBridge;