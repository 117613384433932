var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.uploaderSettings.class || "file-box" },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v(
          " " +
            _vm._s(_vm.uploaderSettings.title || _vm.uploaderSettings.catalog) +
            " "
        ),
        _vm.uploaderSettings.required
          ? _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
          : _vm._e(),
      ]),
      _vm.uploaderSettings.tips
        ? _c("div", { staticClass: "tips" }, [
            _vm._v(_vm._s(_vm.uploaderSettings.tips)),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "img-box" },
        [
          _vm.ismustGeoLocation
            ? _c("FinderFrame", {
                attrs: {
                  mustGeoLocation: true,
                  geoLocation: _vm.mygeoLocation,
                  gateway: _vm.gateway,
                  multiple: _vm.uploaderSettings.multiple || false,
                  maxSize: _vm.uploaderSettings.maxSize || 5,
                  uploadTimeout: 120000,
                  actions: _vm.uploaderSettings.actions || _vm.actions,
                  isPreview: true,
                  h5FilePreview: _vm.uploaderSettings.h5FilePreview || false,
                  canDelExpr: _vm.uploaderSettings.canDelExpr || "",
                  onlyPreview: _vm.uploaderSettings.onlyPreview,
                  filterData: _vm.uploaderSettings.filterData,
                  isMapping: true,
                },
                on: { noGps: _vm.showwarning },
              })
            : _c("FinderFrame", {
                attrs: {
                  gateway: _vm.gateway,
                  maxSize: _vm.uploaderSettings.maxSize || 5,
                  geoLocation: _vm.tempLocation,
                  uploadTimeout: 120000,
                  multiple: _vm.uploaderSettings.multiple || false,
                  actions: _vm.uploaderSettings.actions || _vm.actions,
                  isPreview: true,
                  h5FilePreview: _vm.uploaderSettings.h5FilePreview || false,
                  canDelExpr: _vm.uploaderSettings.canDelExpr || "",
                  onlyPreview: _vm.uploaderSettings.onlyPreview,
                  filterData: _vm.uploaderSettings.filterData,
                  isMapping: true,
                },
              }),
        ],
        1
      ),
      _vm.uploaderSettings.template
        ? _c(_vm.$evalTemplate("Vnode", _vm.uploaderSettings.template), {
            key: "static",
            tag: "component",
          })
        : _vm._e(),
      _vm.uploaderSettings.btns
        ? _c(
            "div",
            { staticClass: "btn-box" },
            _vm._l(_vm.uploaderSettings.btns, function (btn, btnIndex) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: btn.show,
                      expression: "btn.show",
                    },
                  ],
                  key: btnIndex,
                  staticClass: "btn-item",
                  class: btn.class || "normal",
                  on: {
                    click: function ($event) {
                      return _vm.fileBtnClick(btn)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(btn.btnLabel) + " ")]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }