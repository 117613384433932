<template>
    <div :class="uploaderSettings.class || 'file-box'">
        <div class="title">
            {{ uploaderSettings.title || uploaderSettings.catalog }}
            <span v-if="uploaderSettings.required" style="color: red">*</span>
        </div>
        <div v-if="uploaderSettings.tips" class="tips">{{ uploaderSettings.tips }}</div>
        <div class="img-box">
            <FinderFrame v-if="ismustGeoLocation" :mustGeoLocation="true"
                         @noGps="showwarning" :geoLocation="mygeoLocation"
                         :gateway="gateway" :multiple="uploaderSettings.multiple || false"
                         :maxSize="uploaderSettings.maxSize || 5" :uploadTimeout="120000"
                         :actions="uploaderSettings.actions || actions" :isPreview="true"
                         :h5FilePreview="uploaderSettings.h5FilePreview || false"
                         :canDelExpr="uploaderSettings.canDelExpr || ''"
                         :onlyPreview="uploaderSettings.onlyPreview" :filterData="uploaderSettings.filterData"
                         :isMapping="true" />
            <FinderFrame v-else :gateway="gateway" :maxSize="uploaderSettings.maxSize || 5"
                         :geoLocation="tempLocation" :uploadTimeout="120000" :multiple="uploaderSettings.multiple || false"
                         :actions="uploaderSettings.actions || actions" :isPreview="true"
                         :h5FilePreview="uploaderSettings.h5FilePreview || false"
                         :canDelExpr="uploaderSettings.canDelExpr || ''"
                         :onlyPreview="uploaderSettings.onlyPreview"
                         :filterData="uploaderSettings.filterData" :isMapping="true" />
        </div>
        <component key="static" v-if="uploaderSettings.template" :is="$evalTemplate('Vnode', uploaderSettings.template)"></component>
        <div class="btn-box" v-if="uploaderSettings.btns">
            <div class="btn-item"
                 :class="btn.class || 'normal'"
                 v-show="btn.show"
                 @click="fileBtnClick(btn)"
                 v-for="(btn,btnIndex) in uploaderSettings.btns" :key="btnIndex">
                {{ btn.btnLabel }}
            </div>
        </div>
    </div>
</template>

<script>
import FinderFrame from "./FinderFrame.vue";

export default {
    name: "index",
    components: {
        FinderFrame,
    },
    props: {
        uploaderSettings: Object,
        mygeoLocation: Object,
        tempLocation: Object,
        ismustGeoLocation: null,
    },
    mounted() {
        console.log("====上传文件配置", this.uploaderSettings.catalog, this.uploaderSettings, FinderFrame);
    },
    data() {
        return {
            actions: [
                { actionType: "carame", actionLabel: "拍照" },
                { actionType: "album", actionLabel: "相册", accepts: "image/*" },
            ],
            gateway: process.env.VUE_APP_GATEWAY
        };
    },
    methods: {
        fileBtnClick(btn) {
            if(btn.disabled) return false
            this.$emit('fileBtnCallBack',btn)
        },
        showwarning() {
            this.$itoast.fail('请检查"网络是否正常"或者"移动设备的定位是否开启且允许(会引擎APP/企业微信)获取位置信息"');
        }
    },
};
</script>

<style lang="less" scoped>
.file-box {
    margin-top: 0.2rem;
    .title {
        text-align: left;
        font-size: 0.16rem;
        font-weight: 500;
        color: #000000;
    }
    .tips {
        //margin-left: -17%;
        margin-top: 0.05rem;
        margin-bottom: 0.05rem;
        font-size: 0.12rem;
        //color: rgb(201, 14, 14);
        color: red;
        //transform: scale(0.7);
        //-ms-transform: scale(0.7); /* IE 9 */
        //-webkit-transform: scale(0.7); /* Safari and Chrome */
    }
    .img-box {
        margin-top: 0.05rem;
        .item {
            width: 1rem;
            height: 1rem;
            background: #f8f8f8;
            border-radius: 0.13rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .upload-icon {
                width: 0.3rem !important;
                height: 0.3rem !important;
                display: block;
            }
        }
    }
    .btn-box {
        margin-top: 0.05rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn-item {
            height: 0.3rem;
            border-radius: 0.15rem;
            font-size: 0.14rem;
            padding: 0 0.16rem;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            margin-left: 0.1rem;
        }
        .normal {
            background: linear-gradient(-55deg, #4479F1, #3875FE);
            box-shadow: 0px 0.02rem 0.08rem 0px rgba(13, 49, 129, 0.2);
            color: #FFFFFF;
        }
        .del {
            background: #F5F5F5;
            color: #13161B;
        }
        .cancel {
            background: red;
            color: #FFFFFF;
        }
    }
    .desc {
        font-size: 0.12rem;
        margin-top: 0.05rem;
    }
}
.box-border {
    padding-bottom: 0.2rem;
    border-bottom: 1px solid #F5F5F5;
}
</style>
